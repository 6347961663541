import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { CommandModel, EditSettingsModel, GridComponent, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { ToastComponent, ToastPositionModel } from "@syncfusion/ej2-angular-notifications";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { ToolbarClickEventArgs } from "@syncfusion/ej2-filemanager";
import { OrganizationFrequency } from "src/app/interfaces/TechnicalVulnerability/OrganizationFrequency";
import { AdministrationService } from "src/app/shared/services/admin.service";
const localexs = {
    "xs": {
        grid: {
            Add: 'Add Technical vulnerability'
        }
    }
};

setCulture("xs");
L10n.load(localexs);
@Component({
    selector: 'admin-technical-vulnerabilities',
    styleUrls: ['technical-vulnerabilities.css'],
    templateUrl: 'technical-vulnerabilities.html',
    encapsulation: ViewEncapsulation.None
})
export class OrganizationTechnicalVulnerabilitiesComponent implements OnInit, AfterViewInit {
    constructor(private adminService: AdministrationService) { }
    ngAfterViewInit(): void {
        if (document.getElementById('submitVulnBtn')) {
            document.getElementById('submitVulnBtn').style.display = 'none';
        }
        if (document.getElementById('resetVulnBtn')) {
            document.getElementById('resetVulnBtn').style.display = 'none';
        }
    }
    @ViewChild('ddlFilterTemplate', { static: true }) public ddlFilterTemplate!: TemplateRef<any>;
    public filterData: Object[] = [
        { Id: '0', Name: 'Default technical vulnerability' },
        { Id: '1', Name: 'Custom technical vulnerability' }
    ];
    public watermark: string = 'All';
    // maps the appropriate column to fields property
    public frequencies: any = [];
    filteredFrequencies = [];
    showSecondSelect = false;
    public fields: Object = { text: 'Name', value: 'Id' };
    public vulnerabilities: any = [];
    public selectionSettings: Object;
    public commands?: CommandModel[]
    public toolbar: ToolbarItems[] | object;
    public filterSettings: Object;
    public editSettings: EditSettingsModel;
    public isSubmitted: boolean;
    public org: string;
    public mode: string;
    public selectedVulnerabilityTypes: string[] = [];
    public lines = 'default'
    public frequencyModel: OrganizationFrequency;

    @ViewChild('gridAdminVulnerabilities')
    public grid?: GridComponent;

    @ViewChild('toasttype')
    private toastObj: ToastComponent;

    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        },

        {
            title: 'Error!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-danger', icon: 'e-danger toast-icons'
        }
    ];
    public toastPosition: ToastPositionModel = { X: 'Right', Y: 'Bottom' };

    ngOnInit(): void {
        this.org = sessionStorage.getItem("customerId");
        this.mode = 'CheckBox';
        this.getFrequencies();
        this.getOrganizationFrequencies();
        this.getSubmitInfo();
        this.isSubmitted = false;
        this.filterSettings = { type: "Menu" };
        this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };
        this.commands = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-edit e-icons' } },
        { type: 'Delete', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-delete e-icons' } },
        { type: 'Save', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-update e-icons' } }];
        this.getVulnerabilitiesList();
        this.toolbar = [
            'Add', 'Delete',
            { text: 'Submit vulnerabilities', id: 'submitVulnBtn' },
            { template: this.ddlFilterTemplate, id: 'filterVulnBtn' },
            { text: 'Reset to default', id: 'resetVulnBtn' },

        ];

        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: false, mode: 'Normal' };
    }

    rowSelected(e) {
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };
    }
    rowDeselected(e) {
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: false, mode: 'Normal' };
    }

    getVulnerabilitiesList() {
        this.adminService.getTechnicalVulnerabilitiesByOrganization(this.org).subscribe(data => {
            this.vulnerabilities = data;
        },
            error => {
                console.error('Error fetching technical vulnerabilities:', error);
            })
    }

    actionBegin(args: any): void {
        if (args.requestType === 'save' && args.action === 'add') {
            this.adminService.addOrganizationTechnicalVulnerability(args.data, this.org).subscribe(resp => {
                args.data.vulnerabilityId = resp;
                args.data.isCustom = true;
            })
        }
        if (args.requestType === 'save' && args.action === 'edit') {
            this.adminService.updateOrganizationVulnerability(args.data, this.org).subscribe();
            args.data.isCustom = true;
        }
        if (args.requestType === 'delete') {
            if (!args.data[0].isCustom && !this.isSubmitted) {
                return;
            }
            this.adminService.deleteOrganizationTechnicalVulnerability(args.data[0].vulnerabilityId, args.data[0].isCustom, this.org)
                .subscribe(() => {
                    this.toasts[1].content = "Vulnerability deleted";
                    this.toastObj.show(this.toasts[1]);
                },
                    error => {
                        console.error('Error deleting vulnerability:', error);
                        this.toasts[2].content = "Failed to delete row";
                        this.toastObj.show(this.toasts[2]);
                    })
        }
    }

    clickHandler(args: ToolbarClickEventArgs): void {
        if (args.item.id === 'submitVulnBtn' && !this.isSubmitted) {
            this.submitVulnerabilities();
        }
        if (args.item.id === 'resetVulnBtn' && this.isSubmitted) {
            this.resetVulnerabilities();
        }
    }

    submitVulnerabilities() {
        this.adminService.SubmitOrganizationAdminVulnerabilities(this.vulnerabilities, this.org).subscribe(
            () => {
                this.toasts[1].content = "Vulnerabilities submitted";
                this.toastObj.show(this.toasts[1]);
            },
            error => {
                console.error('Error submitting vulnerabilities:', error);
                this.toasts[2].content = "Failed to submit data";
                this.toastObj.show(this.toasts[2]);
            })
    }

    resetVulnerabilities() {
        this.adminService.resetVulnerabilities(this.org).subscribe(
            () => {
                this.toasts[1].content = "Vulnerabilities successfully reset to defaults, please refresh the page";
                this.toastObj.show(this.toasts[1]);
            },
            error => {
                console.error('Error resetting vulnerabilities` to defaults:', error);
                this.toasts[2].content = "Failed to reset data";
                this.toastObj.show(this.toasts[2]);
            })
    }

    filterVulnerabilities(event: any): void {
        const tl = event.itemData.Id;
        if (event.name === "select") {
            this.selectedVulnerabilityTypes.push(tl);
        }
        if (event.name === "removing") {
            const index = this.selectedVulnerabilityTypes.indexOf(tl);
            if (index > -1) {
                this.selectedVulnerabilityTypes.splice(index, 1);
            }
        }
        this.applyFilters();
    }

    applyFilters() {
        if (this.selectedVulnerabilityTypes.length === 0) {
            this.grid.dataSource = this.vulnerabilities;
        } else {
            const includeCustom = this.selectedVulnerabilityTypes.includes("1");
            const includeNonCustom = this.selectedVulnerabilityTypes.includes("0");

            const filteredData = this.vulnerabilities.filter(item => {
                return (
                    (includeCustom && item.isCustom === true) ||
                    (includeNonCustom && item.isCustom === false)
                );
            });

            this.grid.dataSource = filteredData;
        }
        this.grid.refresh();
    }
    getFrequencies() {
        this.adminService.getFrequencyList().subscribe(item => {
            this.frequencies = item;
        })
    }
    getOrganizationFrequencies() {
        this.adminService.getOrganizationFrequencies(this.org).subscribe(item => {
            this.frequencyModel = item;
            if (this.frequencyModel.defaultPlanningFrequency.frequencyId !== 0 && this.frequencyModel.defaultPlanningFrequency.frequencyId !== 1) {
                var selectedFrequencyId = this.frequencyModel.defaultPlanningFrequency.frequencyId;
                this.filteredFrequencies = this.frequencies.filter(freq =>
                    freq.frequencyId !== selectedFrequencyId && freq.frequencyId < selectedFrequencyId
                );
                this.showSecondSelect = true;
            }
        })
    }

    onFrequencyChange(selectedId: string): void {
        const selectedFrequencyId = parseInt(selectedId, 10);
        this.filteredFrequencies = this.frequencies.filter(freq =>
            freq.frequencyId !== selectedFrequencyId && freq.frequencyId < selectedFrequencyId
        );
        this.showSecondSelect = selectedFrequencyId !== 1;
        this.frequencyModel.defaultPlanningFrequency.frequencyId = selectedFrequencyId;
        if (selectedFrequencyId === 1) {
            this.frequencyModel.planningWheelFrequency.frequencyId = selectedFrequencyId;
        }
        this.adminService.addUpdateFrequency(this.frequencyModel).subscribe(data => {
            this.frequencyModel.id = data;
        });
    }

    onPlanningFrequencyChange(selectedId: string): void {
        const selectedFrequencyId = parseInt(selectedId, 10);
        this.frequencyModel.planningWheelFrequency.frequencyId = selectedFrequencyId;
        this.adminService.addUpdateFrequency(this.frequencyModel).subscribe(data => {
            this.frequencyModel.id = data;
        });
    }

    getSubmitInfo() {
        this.adminService.vulnerabilitySubmitInfo(this.org).subscribe(item => {
            this.isSubmitted = item;
            if (this.isSubmitted) {
                document.getElementById('submitVulnBtn').style.display = 'none';
                document.getElementById('resetVulnBtn').style.display = 'block';
            }
            else {
                document.getElementById('submitVulnBtn').style.display = 'block';
                document.getElementById('resetVulnBtn').style.display = 'none';
            }
        },
            error => {
                console.error('Error fetching vulnerabilities submit info:', error);
            })
    }
}