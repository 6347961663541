import { Component, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { AccordionComponent, MenuComponent, SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { MenuItemModel } from '@syncfusion/ej2-navigations';
import { MsalService } from '@azure/msal-angular';
import { GroupData } from 'src/app/enums/groups';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { OrganizationService } from 'src/app/shared/services/organization.service';
import { BPService } from 'src/app/shared/services/bp.service';

@Component({
    selector: 'app-main',
    styleUrls: ['main-layout.css'],
    templateUrl: 'main-layout.html',
    encapsulation: ViewEncapsulation.None
})
export class MainLayoutComponent implements OnInit {
    customer: string;
    isDPO: boolean = false;
    showFunction: boolean;
    isComplianceManager: boolean = false;
    isDataController: boolean = false;
    isAdmin: boolean = false;
    isRequestManager: boolean = false;
    isIncidentManager: boolean = false;
    isProcessOwner: boolean = false;
    isProcessOwnerHR: boolean = false;
    isProcessOwnerIT: boolean = false;
    isOrganizationAdmin: boolean = false;
    isApplicationManager: boolean = false;
    menuState: string;
    @ViewChild('sidebarMenuInstance')
    public sidebarMenuInstance: SidebarComponent;
    public width: string = '317px';
    public mediaQuery: string = ('(min-width: 600px)');
    public target: string = '.main-content';
    public dockSize: string = '50px';
    public enableDock: boolean = true;

    public menu: MenuComponent;

    constructor(
        private authService: MsalService,
        private organizationService: OrganizationService,
        public bpService: BPService) {
    }
    ngOnInit(): void {
        this.showFunction = false;
        this.getRole();
        this.getCustomer();
    }
    @ViewChild('sidebarMenuInstance')
    public menuObj: SidebarComponent
    @ViewChild('sample')
    public listObj: DropDownListComponent;

    @ViewChild('menuAccordion') acrdnInstance?: AccordionComponent;
    public isExpanded = true;
    // define the JSON of data
    public menuData: Object[] = [
        { Id: '1', Game: 'GDPR visual models' },
        { Id: '2', Game: 'Risk assesments' },
        { Id: '3', Game: 'Data processor agreement' },
        { Id: '4', Game: 'Information notice`s' },
        { Id: '5', Game: 'Consents' },
        { Id: '6', Game: 'Data Protection Impact Assesments' }
    ];

    public fields: Object = { text: 'Game', value: 'Id' };
    public height: string = '220px';
    public waterMark: string = 'Select menu item';
    public value: string = 'GDPR visual models';
    public onChange(args: any): void {
        let value: Element = document.getElementById('value');
        let text: Element = document.getElementById('text');
    }
    ngAfterViewInit(e: any): void {
        // call the change event's function after initialized the component.
        setTimeout(() => {
            this.onChange(e);
        })
    }

    showHideFunction(show: boolean) {
        this.showFunction = show;
    }

    getCustomer() {
        if (sessionStorage.getItem("customerId")) {
            this.customer = sessionStorage.getItem("customerId");
            this.showFunctions();
        }
        else {
            var tenant = this.authService.instance.getAllAccounts()[0].tenantId;
            this.bpService.getCustomerId(tenant).subscribe(data => {
                if (data) {
                    this.customer = sessionStorage.getItem("customerId");
                    this.showFunctions();
                }
            })
        }
    }

    showFunctions() {
        this.organizationService.isShowFunctions(this.customer).subscribe(res => {
            this.showFunction = res;
        })
    }

    logout() {
        sessionStorage.removeItem("group");
        sessionStorage.removeItem("role");
        sessionStorage.removeItem("current_group");
        sessionStorage.removeItem("bpListData");
        sessionStorage.removeItem("threatData")
        sessionStorage.removeItem("appData")
        sessionStorage.removeItem("bpId")
        sessionStorage.removeItem("dpia")
        sessionStorage.removeItem("sbmtInfo")
        sessionStorage.removeItem("legalBasisApps")
        this.authService.logoutRedirect();
    }
    getRole() {
        var groups = sessionStorage.getItem("group");
        if (groups === null || groups === "undefined") {
            // groups = this.authService.instance.getAllAccounts()[0].idTokenClaims["groups"];
            sessionStorage.setItem("group", JSON.stringify(this.authService.instance.getAllAccounts()[0].idTokenClaims["groups"]));
            groups = sessionStorage.getItem("group");
        }
        else {
            groups = JSON.parse(sessionStorage.getItem("group"));
        }
        if (groups) {
            if (groups.indexOf(GroupData.ComplianceManagerGroupId) > -1) {
                this.isComplianceManager = true;
                this.isDataController = false;
                this.isAdmin = false;
                this.isRequestManager = false;
                this.isIncidentManager = false;
                this.isDPO = false;
                this.isProcessOwner = false;
                this.isProcessOwnerIT = false;
                this.isProcessOwnerHR = false;
                this.isOrganizationAdmin = false;
                this.isApplicationManager = false;
                sessionStorage.removeItem("current_group");
                sessionStorage.setItem("current_group", JSON.stringify(GroupData.ComplianceManagerGroupId));
                sessionStorage.removeItem("role");
                sessionStorage.setItem("role", "isComplianceManager");
            }
            if (groups.indexOf(GroupData.DataControllerGroupId) > -1) {
                this.isComplianceManager = false;
                this.isDataController = true;
                this.isAdmin = false;
                this.isIncidentManager = false;
                this.isRequestManager = false;
                this.isDPO = false;
                this.isProcessOwner = false;
                this.isProcessOwnerIT = false;
                this.isProcessOwnerHR = false;
                this.isOrganizationAdmin = false;
                this.isApplicationManager = false;
                sessionStorage.removeItem("current_group");
                sessionStorage.setItem("current_group", JSON.stringify(GroupData.DataControllerGroupId));
                sessionStorage.removeItem("role");
                sessionStorage.setItem("role", "isDataController");
            }

            else if (groups.indexOf(GroupData.RequestManagerGroupId) > -1) {
                this.isComplianceManager = false;
                this.isDataController = false;
                this.isAdmin = false;
                this.isRequestManager = true;
                this.isIncidentManager = false;
                this.isDPO = false;
                this.isProcessOwner = false;
                this.isProcessOwnerIT = false;
                this.isProcessOwnerHR = false;
                this.isOrganizationAdmin = false;
                this.isApplicationManager = false;
                sessionStorage.removeItem("current_group");
                sessionStorage.setItem("current_group", JSON.stringify(GroupData.RequestManagerGroupId));
                sessionStorage.removeItem("role");
                sessionStorage.setItem("role", "isRequestManager");
            }

            else if (groups.indexOf(GroupData.AdminGroupId) > -1) {
                this.isComplianceManager = false;
                this.isDataController = false;
                this.isAdmin = true;
                this.isRequestManager = false;
                this.isIncidentManager = false;
                this.isDPO = false;
                this.isProcessOwner = false;
                this.isProcessOwnerIT = false;
                this.isProcessOwnerHR = false;
                this.isOrganizationAdmin = false;
                this.isApplicationManager = false;
                sessionStorage.removeItem("current_group");
                sessionStorage.setItem("current_group", JSON.stringify(GroupData.AdminGroupId));
                sessionStorage.removeItem("role");
                sessionStorage.setItem("role", "isAdmin");
            }

            else if (groups.indexOf(GroupData.IncidentManagerGroupId) > -1) {
                this.isComplianceManager = false;
                this.isDataController = false;
                this.isAdmin = false;
                this.isRequestManager = false;
                this.isIncidentManager = true;
                this.isDPO = false;
                this.isProcessOwner = false;
                this.isProcessOwnerIT = false;
                this.isProcessOwnerHR = false;
                this.isOrganizationAdmin = false;
                this.isApplicationManager = false;
                sessionStorage.removeItem("current_group");
                sessionStorage.setItem("current_group", JSON.stringify(GroupData.IncidentManagerGroupId));
                sessionStorage.removeItem("role");
                sessionStorage.setItem("role", "isIncidentManager");
            }

            else if (groups.indexOf(GroupData.DPOGroupId) > -1) {
                this.isComplianceManager = false;
                this.isDataController = false;
                this.isAdmin = false;
                this.isRequestManager = false;
                this.isIncidentManager = false;
                this.isDPO = true;
                this.isProcessOwner = false;
                this.isProcessOwnerIT = false;
                this.isProcessOwnerHR = false;
                this.isOrganizationAdmin = false;
                this.isApplicationManager = false;
                sessionStorage.removeItem("current_group");
                sessionStorage.setItem("current_group", JSON.stringify(GroupData.DPOGroupId));
                sessionStorage.removeItem("role");
                sessionStorage.setItem("role", "isDPO");
            }

            else if (groups.indexOf(GroupData.ProcessOwnerGroupId) > -1) {
                this.isComplianceManager = false;
                this.isDataController = false;
                this.isAdmin = false;
                this.isRequestManager = false;
                this.isIncidentManager = false;
                this.isDPO = false;
                this.isProcessOwner = true;
                this.isProcessOwnerIT = false;
                this.isProcessOwnerHR = false;
                this.isOrganizationAdmin = false;
                this.isApplicationManager = false;
                sessionStorage.removeItem("current_group");
                sessionStorage.setItem("current_group", JSON.stringify(GroupData.ProcessOwnerGroupId));
                sessionStorage.removeItem("role");
                sessionStorage.setItem("role", "isProcessOwner");
            }

            else if (groups.indexOf(GroupData.ProcessOwnerHRGroupId) > -1) {
                this.isComplianceManager = false;
                this.isDataController = false;
                this.isAdmin = false;
                this.isRequestManager = false;
                this.isIncidentManager = false;
                this.isDPO = false;
                this.isProcessOwner = false;
                this.isProcessOwnerIT = false;
                this.isProcessOwnerHR = true;
                this.isOrganizationAdmin = false;
                this.isApplicationManager = false;
                sessionStorage.removeItem("current_group");
                sessionStorage.setItem("current_group", JSON.stringify(GroupData.ProcessOwnerHRGroupId));
                sessionStorage.removeItem("role");
                sessionStorage.setItem("role", "isProcessOwnerHR");
            }

            else if (groups.indexOf(GroupData.ProcessOwnerITGroupId) > -1) {
                this.isComplianceManager = false;
                this.isDataController = false;
                this.isAdmin = false;
                this.isRequestManager = false;
                this.isIncidentManager = false;
                this.isDPO = false;
                this.isProcessOwner = false;
                this.isProcessOwnerIT = true;
                this.isProcessOwnerHR = false;
                this.isOrganizationAdmin = false;
                this.isApplicationManager = false;
                sessionStorage.removeItem("current_group");
                sessionStorage.setItem("current_group", JSON.stringify(GroupData.ProcessOwnerITGroupId));
                sessionStorage.removeItem("role");
                sessionStorage.setItem("role", "isProcessOwnerIT");
            }

            else if (groups.indexOf(GroupData.OrganizationAdminGroupId) > -1) {
                this.isComplianceManager = false;
                this.isDataController = false;
                this.isAdmin = false;
                this.isRequestManager = false;
                this.isIncidentManager = false;
                this.isDPO = false;
                this.isProcessOwner = false;
                this.isProcessOwnerIT = false;
                this.isProcessOwnerHR = false;
                this.isOrganizationAdmin = true;
                this.isApplicationManager = false;
                sessionStorage.removeItem("current_group");
                sessionStorage.setItem("current_group", JSON.stringify(GroupData.OrganizationAdminGroupId));
                sessionStorage.removeItem("role");
                sessionStorage.setItem("role", "isOrganizationAdmin");
            }

            else if (groups.indexOf(GroupData.ApplicationManagerGroupId) > -1) {
                this.isComplianceManager = false;
                this.isDataController = false;
                this.isAdmin = false;
                this.isRequestManager = false;
                this.isIncidentManager = false;
                this.isDPO = false;
                this.isProcessOwner = false;
                this.isProcessOwnerIT = false;
                this.isProcessOwnerHR = false;
                this.isOrganizationAdmin = false;
                this.isApplicationManager = true;

                sessionStorage.removeItem("current_group");
                sessionStorage.setItem("current_group", JSON.stringify(GroupData.ApplicationManagerGroupId));
                sessionStorage.removeItem("role");
                sessionStorage.setItem("role", "isApplicationManager");
            }
        }
    }

    getName(): string {
        if (this.authService.instance.getAllAccounts()[0] == null) {
            return 'unknown'
        }
        return this.authService.instance.getAllAccounts()[0].name
    }

    public homeMenuItem: MenuItemModel[] = [
        {
            text: 'Home',
            iconCss: 'icon-user icon',
            url: '/home'
        }
    ];

    // Data Controller
    public menuItemsDataHRGDPR: MenuItemModel[] = [
        {
            text: 'GDPR visual models',
            iconCss: 'icon-globe icon',
            url: '/'
        }
    ];

    public menuItemsDataHRRisk: MenuItemModel[] = [
        {
            text: 'Risk assesments',
            iconCss: 'icon-globe icon',
            url: '/'
        }
    ];

    public menuItemsDPOPrivacyInformation: MenuItemModel[] = [
        {
            text: 'THE PRIVACY INFORMATION MANAGEMENT SYSTEM',
            iconCss: 'icon-globe icon',
            url: '/'
        }
    ];

    public menuItemsDPOPolicies: MenuItemModel[] = [
        {
            text: 'POLICIES, GUIDELINES, PROCEDURES & TEMPLATES',
            iconCss: 'icon-globe icon',
            url: '/'
        }
    ];

    public menuItemsDataHRDataProtection: MenuItemModel[] = [
        {
            text: 'Data protection impact agreements',
            iconCss: 'icon-globe icon',
            url: '/'
        }
    ];
    public menuItemsDataHRInfo: MenuItemModel[] = [
        {
            text: 'Information notices',
            iconCss: 'icon-bell-alt icon',
            url: '/'
        }
    ];
    public menuItemsDataHRConcents: MenuItemModel[] = [
        {
            text: 'Consents',
            iconCss: 'icon-bell-alt icon',
            url: '/'
        }
    ];
    public menuItemsDataHRRecords: MenuItemModel[] = [
        {
            text: 'RECORDS OF PROCESSING',
            iconCss: 'icon-bell-alt icon',
            url: '/'
        }
    ];
    public menuItemsDataHRDashboard: MenuItemModel[] = [
        {
            text: 'My dashboard',
            iconCss: 'icon-bell-alt icon',
            url: '/'
        }
    ];
    public menuItemsDataHRActions: MenuItemModel[] = [
        {
            text: 'My action plan',
            iconCss: 'icon-bell-alt icon',
            url: '/action-plan'
        }
    ];

    public menuItemsDataProcessorAgreements: MenuItemModel[] = [
        {
            text: 'Data processor agreements',
            iconCss: 'icon-bell-alt icon',
            url: '/'
        }
    ];

    //Process Owner

    public menuItemsPOBps: MenuItemModel[] = [
        {
            text: 'Business processes',
            iconCss: 'icon-bell-alt icon',
            url: '/'
        }
    ];

    public menuItemsPORisks: MenuItemModel[] = [
        {
            text: 'My risks',
            iconCss: 'icon-bell-alt icon',
            url: '/'
        }
    ];

    public menuItemsPOActionPlan: MenuItemModel[] = [
        {
            text: 'My action plan',
            iconCss: 'icon-bell-alt icon',
            url: '/initial-action-plan'
        }
    ];

    public menuItemsPORules: MenuItemModel[] = [
        {
            text: 'My rules',
            iconCss: 'icon-bell-alt icon',
            url: '/my-rule-book'
        }
    ];

    //Process Owner

    //Compliance manager

    public menuItemsCMBps: MenuItemModel[] = [
        {
            text: 'Enterprise Business processes',
            iconCss: 'icon-bell-alt icon',
            url: '/'
        }
    ];

    public menuItemsCMRisks: MenuItemModel[] = [
        {
            text: 'Enterprise risks',
            iconCss: 'icon-bell-alt icon',
            url: '/'
        }
    ];

    public menuItemsCMActionPlan: MenuItemModel[] = [
        {
            text: 'Enterprise action plan',
            iconCss: 'icon-bell-alt icon',
            url: '/'
        }
    ];

    public menuItemsCMRules: MenuItemModel[] = [
        {
            text: 'Enterprise rules compliance',
            iconCss: 'icon-bell-alt icon',
            url: '/'
        }
    ];

    public menuItemsCMBasicInfo: MenuItemModel[] = [
        {
            text: 'Enterprise basic info',
            iconCss: 'icon-bell-alt icon',
            url: '/organization-basic-info'
        }
    ];

    //Compliance manager

    //ADMIN

    public menuItemsAdminImport: MenuItemModel[] = [
        {
            text: 'IMPORT ARCHI MODEL',
            iconCss: 'icon-bell-alt icon',
            url: '/model-import'
        }
    ];

    public menuItemsAdminDpo: MenuItemModel[] = [
        {
            text: 'DPO REPORTS',
            iconCss: 'icon-bell-alt icon',
            url: '/dpo_report'
        }
    ];

    public menuItemsAdminControl: MenuItemModel[] = [
        {
            text: 'CONTROL REPORTS',
            iconCss: 'icon-bell-alt icon',
            url: '/control_report'
        }
    ];

    public menuItemsAdminAdmin: MenuItemModel[] = [
        {
            text: 'ADMINISTRATION',
            iconCss: 'icon-bell-alt icon',
            url: '/administration'
        }
    ];

    public menuItemsAdminPermissions: MenuItemModel[] = [
        {
            text: 'MANAGE ROLE PERMISSIONS',
            iconCss: 'icon-bell-alt icon',
            url: '/manage-permissions'
        }
    ];

    public menuItemsAdminThreats: MenuItemModel[] = [
        {
            text: 'THREAT CATALOGUE',
            iconCss: 'icon-bell-alt icon',
            url: '/admin-threats'
        }
    ];

    public menuItemsAdminVulnerabilities: MenuItemModel[] = [
        {
            text: 'TECHNICAL VALNERABILITIES',
            iconCss: 'icon-bell-alt icon',
            url: '/technical-vulnerabilities'
        }
    ];

    openClick(stayCollapsed: boolean) {
        var roleForResise = this.isProcessOwner || this.isComplianceManager || this.isProcessOwnerIT || this.isOrganizationAdmin;
        var arrNew = document.getElementById('resizeBtnNew');
        var expandIco = document.getElementById('expandMenuImg');
        var menu = document.getElementById('sidebar-menu');
        var header = document.getElementById('header');
        var fullLogo = document.getElementById('fullLogo');
        var logoHeader = document.getElementById('logo-header');
        var processOwnerMenuWrapper = document.querySelectorAll(".POSubMentWrapper");
        if (!stayCollapsed) {
            if (menu.style.width == '50px') {
                this.sidebarMenuInstance.toggle();
                arrNew.style.display = 'block';
                arrNew.style.marginTop = '-15px';
                expandIco.style.display = 'none';
                this.menuState = "collapsed";
                header.style.marginLeft = '317px';
                fullLogo.style.display = 'block';
                arrNew.style.float = 'right';
                logoHeader.style.backgroundColor = '#0E384D';
                if (roleForResise) {
                    processOwnerMenuWrapper.forEach(function (element) {
                        (element as HTMLElement).style.padding = '5px 5px 5px 35px';
                    });
                }
            }
            else {
                this.sidebarMenuInstance.toggle();
                arrNew.style.display = 'none';
                expandIco.style.display = 'block';
                this.menuState = "collapsed";
                header.style.marginLeft = '50px'
                fullLogo.style.display = 'none';
                arrNew.style.float = 'left';
                logoHeader.style.backgroundColor = 'white';
                if (roleForResise) {
                    processOwnerMenuWrapper.forEach(function (element) {
                        (element as HTMLElement).style.padding = '0px';
                    });
                }
            }
        }
        if (stayCollapsed) {
            if (stayCollapsed === true) {
                if (menu.style.width != '50px') {
                    this.sidebarMenuInstance.toggle();
                    arrNew.style.display = 'none';
                    expandIco.style.display = 'block';
                    this.menuState = "collapsed";
                    header.style.marginLeft = '50px'
                    fullLogo.style.display = 'none';
                    arrNew.style.float = 'left';
                    logoHeader.style.backgroundColor = 'white';
                    if (roleForResise) {
                        processOwnerMenuWrapper.forEach(function (element) {
                            (element as HTMLElement).style.padding = '0px';
                        });
                    }
                }
            }
        }
    }
};
