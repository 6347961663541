import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { CommandModel, EditSettingsModel, GridComponent, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { AdministrationService } from "src/app/shared/services/admin.service";

@Component({
    selector: 'vulnerability-answers',
    styleUrls: ['technical-vulnerabilities.css'],
    templateUrl: 'technical-vulnerabilities.html',
    encapsulation: ViewEncapsulation.None
})
export class VulnerabilityAnswersComponent implements OnInit {
    constructor(private adminService: AdministrationService) { }
    public vulnerabilities: any = [];
    public answers: any = [];
    public selectionSettings: Object;
    public ddlfields: Object = { text: 'answerId', value: 'answerName' };
    public toolbar: ToolbarItems[] | object;
    public filterSettings: Object;
    public selectedVulnerabilityTypes: string[] = [];
    public lines = 'Default';
    public org: string;
    @ViewChild('ddlFilterTemplate', { static: true }) public ddlFilterTemplate!: TemplateRef<any>;
    public filterData: Object[] = [
        { Id: '0', Name: 'Default technical vulnerability' },
        { Id: '1', Name: 'Custom technical vulnerability' }
    ];
    public fields: Object = { text: 'Name', value: 'Id' };

    @ViewChild('gridAdminVulnerabilities')
    public grid?: GridComponent;
    ngOnInit(): void {
        this.org = sessionStorage.getItem("customerId");
        this.filterSettings = { type: "Menu" };
        this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };
        this.getVulnerabilitiesList();
        this.getAnswersList();
        this.toolbar = [
            { template: this.ddlFilterTemplate, id: 'filterVulnBtn' },
        ];
    }

    getVulnerabilitiesList() {
        this.adminService.getApplicationManagerVulnerabilitiesByOrganization(this.org).subscribe(data => {
            this.vulnerabilities = data;
        },
            error => {
                console.error('Error fetching technical vulnerabilities:', error);
            })
    }

    getAnswersList() {
        this.adminService.getTechnicalVulnerabilityAnswers().subscribe(data => {
            this.answers = data;
        },
            error => {
                console.error('Error fetching answers:', error);
            })
    }

    setAnswer(data: any, args) {
        data.answers.answerId = args.value;
        this.adminService.updateTechnicalVulnerabilityWithAnswer(data, this.org).subscribe();
    }

    filterVulnerabilities(event: any): void {
        const tl = event.itemData.Id;
        if (event.name === "select") {
            this.selectedVulnerabilityTypes.push(tl);
        }
        if (event.name === "removing") {
            const index = this.selectedVulnerabilityTypes.indexOf(tl);
            if (index > -1) {
                this.selectedVulnerabilityTypes.splice(index, 1);
            }
        }
        this.applyFilters();
    }

    applyFilters() {
        if (this.selectedVulnerabilityTypes.length === 0) {
            this.grid.dataSource = this.vulnerabilities;
        } else {
            const includeCustom = this.selectedVulnerabilityTypes.includes("1");
            const includeNonCustom = this.selectedVulnerabilityTypes.includes("0");

            const filteredData = this.vulnerabilities.filter(item => {
                return (
                    (includeCustom && item.isCustom === true) ||
                    (includeNonCustom && item.isCustom === false)
                );
            });

            this.grid.dataSource = filteredData;
        }
        this.grid.refresh();
    }
}